import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import IframeResizer from 'iframe-resizer-react'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | Black Entrepreneurship" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-myred"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        Black Entrepreneurship
      </p>
      <StaticImage
        src="../images/b-6.jpg"
        alt="Image of professional couple"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          My Work and Well-Being Consulting partners with Black Entrepreneurs to
          provide professional services to Black-owned businesses.
        </p>
        <div>
          <p className="text-left text-mypurple text-lg font-bold">
            An overview:
          </p>
          <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
            <li className="mt-4">Black Entrepreneur Needs Analysis Research</li>
            <li className="mt-4">
              Analysis and development of strategic goals and objectives for the
              business
            </li>
            <li className="mt-4">
              Business Entrepreneurship Services and Training
            </li>
            <li className="mt-4">Business Development Services</li>
            <li className="mt-4">
              Black Business Owners’ Leadership Development
            </li>
            <li className="mt-4">
              Black Business Owners' Human Resources Management and Performance
              Management Services
            </li>
            <li className="mt-4">Mental Health and Well-Being at Work</li>
            <li className="mt-4">Entrepreneurial Coaching and Mentoring</li>
            <li className="mt-4">
              Business Planning Development Services and Training
            </li>
            <li className="mt-4">Grant Writing Services</li>
            <li className="mt-4">
              Any customized services in keeping with the Black Business Owner's
              and Business needs requirements.
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <button
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </button>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/b-4.jpg"
          alt="Image of professional woman"
          className="h-full"
        />
      </div>
    </section>
    <section className="py-24">
      <div className="h-80 mx-auto inline">
        <IframeResizer
          title="iframe embed"
          src="https://wallembed.famewall.io/carousel/mwwb-b?autoplay=1"
          checkOrigin={false}
          autoResize={true}
          scrolling={false}
          width="100%"
          style={{
            border: 0,
            width: `1px`,
            minWidth: `100%`,
          }}
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/b-1.jpg"
          alt="Image of professional woman"
          className="h-full"
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/b-2.jpg"
          alt="Image of professional woman"
          className="h-full"
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/b-5.jpg"
          alt="Image of business proprietor"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
